import React from 'react';
import './App.css'; // Подключаем стили

const App: React.FC = () => {
  return (
    <div className="page-container">
      <div className="form-container">
        <button type="button" className="login-button">Вход</button> {/* Кнопка Вход */}
        <h1>Регистрация</h1>
        <p className="form-title">Пожалуйста, заполните форму</p>
        <form>
          <input type="text" name="name" placeholder="Введите ваше имя" required />
          <input type="tel" name="phone" placeholder="Номер телефона" />
          <input type="text" name="emailOrTelegram" placeholder="Email или Telegram" required />
          <input type="password" name="password" placeholder="Пароль" required />
          <div>
            <label>
              <input type="radio" name="role" value="doctor" /> Врач
            </label>
            <label>
              <input type="radio" name="role" value="patient" defaultChecked /> Пациент
            </label>
          </div>
          <select name="language">
            <option value="ru">Русский</option>
            <option value="uz">Узбекский</option>
          </select>
          <button type="submit">Отправить</button>
        </form>
        <div className="side-icons">
          <img src="/icons/language.svg" alt="Язык" />
          <img src="/icons/help.svg" alt="Помощь" />
          <img src="/icons/chat.svg" alt="Чат" />
        </div>
      </div>
    </div>
  );
};

export default App;
